<template>
<div class="root_main">
  <LoginPhone></LoginPhone>
</div>
</template>

<script>
import LoginPhone from './com/LoginPhone.vue'
export default {
  components:{LoginPhone}
}
</script>

<style scoped lang="scss">
.root_main{
  width: 100%;
  height: 100%;
  background-color: #dae2ff;
  background-image: url('https://hs-hospital.oss-cn-hangzhou.aliyuncs.com/image/2024-4-29/bg.png');
  background-size: 100%;
  box-sizing: border-box;
}
</style>