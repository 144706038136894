<template>
  <div class="load" v-if="load">
    <div class="ico"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      load: false
    }
  },
  methods: {
    open() {
      this.load = true
    },
    close() {
      this.load = false
    }
  }
}
</script>

<style scoped lang="scss">
.load {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 50;

  .ico {
    width: 30px;
    height: 30px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -15px;
    margin-top: -15px;
    background-size: 100% 100%;
    background-image: url("../assets/image/load.png");
    transition: 0.5s;
    animation: rotate 1s linear infinite;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

}
</style>
